<template>
  <div class="edit-closer-rate">
    <fd-form ref="closerRateform" @submit="$emit('submit', form)">
      <banner class="bg-blue-100 mb-2" :dismissable="false">
        <p class="fg-grey-900">
          The closer's commission rate will be deducted from your share
          <span class="font-bold"> ({{ specialistRate }}%) </span>
        </p>
      </banner>
      <fd-input
        v-model="form.rates"
        class="col-12 mb-2"
        label="Closer's Rate (%)"
        name="closerRate"
        type="number"
        :validators="[
          validator.required,
          (val) => validator.minValue(val, 0),
          (val) => validator.maxValue(val, 50)
        ]"
        @keydown.enter.native.prevent="$refs.closerRateform.submit"
      >
      </fd-input>

      <display-data
        label="Your Remaining"
        :content="remainingRate"
      ></display-data>

      <div class="d-flex justify-content-end mt-3">
        <fd-button @click="$emit('close')" class="mr-2"> Cancel </fd-button>
        <fd-button type="submit" class="main">
          {{ submitButtonLabel }}
        </fd-button>
      </div>
    </fd-form>
  </div>
</template>

<script>
import {
  required,
  minValue,
  maxValue
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {
    Banner: () => import("@/components/GlobalComponents/Banner"),
    DisplayData: () => import("@/components/GlobalComponents/DisplayData")
  },
  mixins: [],
  props: {
    payload: {
      type: Object,
      default: () => ({
        rates: 0
      })
    },
    property: {
      type: Object
    },
    specialistRate: {
      type: Number
    },
    submitButtonLabel: {
      type: String,
      default: "Save"
    }
  },
  data: function () {
    return {
      validator: {
        required,
        minValue,
        maxValue
      }
    };
  },
  computed: {
    form: {
      get() {
        return this.payload;
      },
      set(value) {
        this.$emit("update:payload", { ...this.payload, value });
      }
    },
    remainingRate() {
      let remaining = this.specialistRate - this.form.rates;

      return remaining < 0
        ? `Closer rate cannot be more than ${this.specialistRate}%`
        : `${remaining}%`;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
